import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/index"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const notFoundTemplateStyles = {
  headerStyle: { textAlign: "center" },
  titleStyle: {
    marginTop: rhythm(1),
    marginBottom: 0,
    fontFamily: `Dosis`,
    fontWeight: 200,
  },
}

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { headerStyle, titleStyle } = notFoundTemplateStyles

  return (
    <Layout location={location} title={siteTitle}>
      <article>
        <SEO title="404: Not Found" />
        <header style={headerStyle}>
          <h2 style={titleStyle}>Not found :(</h2>
        </header>
        <section>
          <div>
            <br />
            <p>You just hit a route that does not exist...</p>
          </div>
          <div>
            <Link to={"/"} rel="prev">
              ← Home
            </Link>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
